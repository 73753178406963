<template>
    <main>
        <h1>Shopping Bag</h1>
    </main>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>